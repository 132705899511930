<template>
  <div v-if="isLoading" class="spinner-overlay">
    <spinner-component />
    <div class="spinner-text">
      {{ iceState ? iceStateMessage : 'Waiting For connection...' }}
    </div>
  </div>
  <div v-show="!isLoading">
    <a-row :gutter="8" class="w-100 d-flex">
      <a-col span="18" class="d-flex flex-column">
        <video v-show="false" id="video" autoplay playsinline muted controls />
        <v-stage
          ref="stage"
          :config="stageSize"
          @mouseDown="handleStageMouseDown"
        >
          <v-layer ref="layer">
            <v-image ref="image" :config="imageConfig" />
            <v-rect
              v-for="(rec, index) in recs"
              v-show="isPixelate"
              :key="index"
              :config="{
                id: index.toString(),
                x: Math.min(rec.x, rec.x + rec.w),
                y: Math.min(rec.y, rec.y + rec.h),
                width: Math.abs(rec.w),
                height: Math.abs(rec.h),
                stroke: selectedIndex === index ? 'green' : 'black',
                strokeWidth: 1,
                draggable: rec.draggable,
                device_settings: deviceSetting
              }"
              @transform="handleTransform"
              @transformend="handleTransformEnd"
              @dragmove="handleDrag"
              @dragend="handleDragEnd($event, index)"
            />
            <v-transformer ref="transformer" :rotateEnabled="isRotateEnabled" />
          </v-layer>
        </v-stage>
      </a-col>
      <a-col span="6" class="d-flex flex-column">
        <a-button
          id="pixelate-button"
          type="primary"
          :disabled="isLoading"
          @click="pixelateVideo"
        >
          <!-- {{ isPixelate ? 'Stop' : 'Start' }} Pixelating -->
          Add Blurred Region
        </a-button>
        <a-list size="small" bordered :data-source="recs">
          <template #renderItem="{ item, index }">
            <a-list-item
              :class="{ 'green-region': selectedIndex === index }"
              @click="seletedRegion(item, index)"
            >
              <template #actions>
                <span
                  :id="'region-delete-' + index"
                  class="cursor-pointer"
                  @click="removeRegion(item, index)"
                >
                  <delete-outlined />
                </span>
              </template>
              <a-list-item-meta>
                <template #title>
                  <span :id="'region-index-' + index"
                    >Region {{ index + 1 }}</span
                  >
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
        <a-button
          class="mt-2"
          :disabled="disableSaveButton"
          @click="updateRegions"
        >
          Save Regions
        </a-button>
      </a-col>
    </a-row>
  </div>
  <div id="mark-blur-regions-instruction" class="mt-2">
    <a-descriptions
      title="Instructions"
      :colon="false"
      size="small"
      :contentStyle="textStyle"
      v-if="!isLoading"
    >
      <a-descriptions-item label="1." span="3">
        Click on <strong> &nbsp; Add Blured Region &nbsp;</strong> button
        present at top-right corner of screen.
      </a-descriptions-item>
      <a-descriptions-item label="2." span="3">
        Adjust the squre box by dragging or expending it to the desired
        position.
      </a-descriptions-item>
      <a-descriptions-item label="3." span="3">
        Click <strong> &nbsp; Save Region &nbsp;</strong> button to save the
        blured region co-ordinates.
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
<script>
import SpinnerComponent from '../../../shared/Components/Spinner';
import WebRtc from 'src/services/webrtc';
import { MediaRTCConnection } from '../devices/ProjectiorRegion/webrtc';
import { DeleteOutlined } from '@ant-design/icons-vue';
import DeviceServices from 'src/services/device.js';
import { mapGetters } from 'vuex';

export default {
  components: { SpinnerComponent, DeleteOutlined },
  inject: ['toast'],
  props: ['serialNumber', 'deviceSetting', 'deviceSettings'],
  emits: ['closeModal'],
  data() {
    return {
      video: null,
      videoParams: null,
      cssPhysicalProportion: 1,
      videoWidth: 640,
      stageSize: {
        width: 640,
        height: 480
      },
      deviceMac: null,
      isLoading: true,
      rtcConn: null,
      answerSearching: null,
      iceState: null,
      mediaConnection: null,
      rtcConfiguration: {},
      animation: null,
      isPixelate: false,
      pixelating: false,
      recs: [],
      newRecs: [],
      isDragging: false,
      selectedRegion: {},
      selectedIndex: null,
      isRegionSelected: false,
      enableUpdateButton: false,
      disableSaveButton: true,
      isOutOfBound: false,
      textStyle: {
        fontSize: '12.8px'
      },
      iceStateMessage: null,
      isRotateEnabled: false
    };
  },
  computed: {
    ...mapGetters(['organization', 'fcmToken']),
    selectedIndexStr() {
      if (!this.selectedIndex) return '';
      return this.selectedIndex.toString();
    },
    imageConfig() {
      return {
        image: this.video,
        x: 0,
        y: 0,
        width: 640,
        height: 480
      };
    }
  },
  watch: {
    iceState(value) {
      console.log('ice state->', value);
      if (value === 'failed') {
        console.log('icestate failed', value);
        this.iceStateMessage = 'Live Stream Failed';
        this.$emit('closeModal');
      } else if (value === 'disconnected') {
        console.log('icestate disconnected', value);
        // this.isLoading = true;
        // this.iceStateMessage = 'Slow network detected. Trying to reconnect...';
        // this.toast.info('Slow network detected. Trying to reconnect...');
      } else if (value === 'connected') {
        this.iceStateMessage = 'Live Stream Connected';
        this.isLoading = false;
      } else if (value === 'checking') {
        this.isLoading = true;
        this.iceStateMessage = 'Checking For Connection...';
      }
    }
  },
  async created() {
    this.deviceMac = this.serialNumber;
    const [error, data] = await DeviceServices.fetchBlurRegions(
      this.deviceSetting
    );
    let blurRegions = data.map(function(item) {
      return { ...item, draggable: false };
    });
    this.setRecs(blurRegions);
    this.deTransformRegions();
  },
  mounted() {
    this.video = document.getElementById('video');
    this.layer = this.$refs.image.getStage().getLayer();
    this.animation = new Konva.Animation(() => {}, this.layer);
    this.video.addEventListener('canplay', () => {
      this.play();
    });
    this.showStream();
  },
  beforeUnmount() {
    this.emitter.off(
      this.serialNumber,
      this.mediaConnection.connectToRemotePeer
    );
    this.mediaConnection.close();
    clearInterval(this.answerSearching);
  },
  methods: {
    transformRegions(data) {
      data.map(obj => {
        obj.w /= 640;
        obj.x /= 640;
        obj.h /= 480;
        obj.y /= 480;
      });
    },
    deTransformRegions() {
      this.recs.map(obj => {
        obj.w *= 640;
        obj.x *= 640;
        obj.h *= 480;
        obj.y *= 480;
      });
    },
    newRegions(item) {
      return this.newRecs.includes(parseInt(item.id));
    },
    async updateRegions() {
      let newRegions = [];
      let updatedRegions = [];
      let error2, error1;
      let updated_data = [];
      let save_response = [];
      this.recs.filter(item => {
        if (this.newRecs.includes(parseInt(item.id))) {
          newRegions.push(item);
        } else {
          updatedRegions.push(item);
        }
      });
      // this.recs.filter(
      //   (item ) => {
      //     if(!this.newRecs.includes(parseInt(item?.id))){
      //       let obj_copy = JSON.parse(JSON.stringify(item));
      //       updatedRegions.push(obj_copy)
      //       obj_copy = {}
      //     }
      //     }
      // );
      this.transformRegions(newRegions);
      this.transformRegions(updatedRegions);

      if (newRegions.length > 0) {
        [error1, save_response] = await DeviceServices.saveBlurRegions(
          newRegions
        );
      }
      if (updatedRegions.length > 0) {
        [error2, updated_data] = await DeviceServices.updateBlurRegions(
          updatedRegions
        );
      }
      error1 || error2
        ? this.toast.error('Error while updating regions')
        : this.toast.success('Regions Updated');
      this.recs = [];
      newRegions = [];
      updatedRegions = [];
      this.recs = [...save_response, ...updated_data];
      this.deTransformRegions();
      this.recs = this.recs.sort((a, b) => a.id - b.id);

      this.disableSaveButton = true;
      this.selectedIndex = null;
      this.isRegionSelected = false;
      this.isDragging = false;
      this.isOutOfBound = false;
      this.newRecs = [];
      this.detachTransformer();
    },
    seletedRegion(item, index) {
      this.isRegionSelected = true;
      this.selectedIndex = index;
      if (this.recs[index]) {
        this.recs[index].draggable = true;
      }
    },
    async showStream() {
      this.rtcConfiguration = await WebRtc.getRTCServers(this.deviceSettings.ice_options);
      this.mediaConnection = new MediaRTCConnection(
        this.serialNumber,
        this.organization,
        this.fcmToken,
        this.video,
        this.rtcConfiguration,
        this.setIceState
      );
      await this.mediaConnection.connectToRemotePeer();
    },

    setIceState: function(value) {
      this.iceState = value;
    },

    play() {
      this.video.play();
      this.animation.start();
    },
    pause() {
      this.video.pause();
      this.animation.stop();
    },
    between(x, min, max) {
      return x >= min && x <= max;
    },
    handleDrag(e) {
      e.target.y() < 0
        ? e.target.y(Math.max(e.target.y(), 0))
        : e.target.y(Math.min(e.target.y(), 480));
      e.target.x() < 0
        ? e.target.x(Math.max(e.target.x(), 0))
        : e.target.x(Math.min(e.target.x(), 640));
    },
    handleTransform(e) {
      var width = e.target.width() * e.target.scaleX();
      var height = e.target.height() * e.target.scaleY();
      const pos = this.$refs.stage.getNode().getPointerPosition();

      if (!this.between(width, 0, 640)) {
        this.isOutOfBound = true;
        var scaleX = 640 / e.target.width();
        e.target.scaleX(scaleX);
      } else if (!this.between(height, 0, 480)) {
        this.isOutOfBound = true;
        var scaleY = 480 / e.target.height();
        e.target.scaleY(scaleY);
      }
      if (pos.x < 0 || pos.x > 640 || pos.y < 0 || pos.y > 480) {
        width > 640 ? e.target.width(640) : e.target.width(width);
        height > 480 ? e.target.height(480) : e.target.height(height);
        this.stopTransformer();
      }
      let x = e.target.x() > 640 ? 640 : e.target.x();
      let y = e.target.y() > 480 ? 480 : e.target.y();
      e.target.x(x < 0 ? 0 : x);
      e.target.y(y < 0 ? 0 : y);
      this.recs[this.selectedIndex].x = e.target.x();
      this.recs[this.selectedIndex].y = e.target.y();
      this.recs[this.selectedIndex].w = e.target.width() * e.target.scaleX();
      this.recs[this.selectedIndex].h = e.target.height() * e.target.scaleY();
    },

    handleTransformEnd(e) {
      if (!this.isOutOfBound) {
        this.recs[this.selectedIndex].x = e.target.x() < 0 ? 0 : e.target.x();
        this.recs[this.selectedIndex].y = e.target.y() < 0 ? 0 : e.target.y();
        this.recs[this.selectedIndex].w *= e.target.scaleX();
        this.recs[this.selectedIndex].h *= e.target.scaleY();
        const pos = this.$refs.stage.getNode().getPointerPosition();
        e.target.scaleX(1);
        e.target.scaleY(1);
      } else {
        this.toast.info('out of bound');
      }
      this.disableSaveButton = false;
    },
    handleStageMouseDown(e) {
      if (e.target === e.target.getStage()) {
        this.selectedIndex = null;
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }
      this.selectedIndex = parseInt(e.target.id());

      this.updateTransformer();
    },
    updateTransformer() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const selectedNode = stage.findOne('#' + this.selectedIndex.toString());
      if (selectedNode === transformerNode.node()) {
        return;
      }
      if (selectedNode) {
        this.recs[this.selectedIndex].draggable = true;
        transformerNode.nodes([selectedNode]);
      } else {
        transformerNode.nodes([]);
      }
    },

    detachTransformer() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.detach();
    },

    stopTransformer() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.stopTransform();
      this.isOutOfBound = false;
    },

    setVideoParams() {
      const self = this;
      const modalBody = document.getElementById('stream-modal-body');
      setTimeout(() => {
        const { top, left, width, height } = self.video.getBoundingClientRect();
        const modalParams = modalBody?.getBoundingClientRect();
        self.videoParams = {
          top: Math.floor(top - modalParams.top),
          left: Math.floor(left - modalParams.left),
          width: Math.floor(width),
          height: Math.floor(height)
        };
        self.cssPhysicalProportion = self.videoWidth / width;
      }, 2000);
    },

    handleDragStart() {
      this.isDragging = true;
      this.pixelating = false;
      this.isPixelate = false;
      this.enableUpdateButton = true;
    },
    handleDragEnd(e, i) {
      const pos = this.$refs.stage.getNode().getPointerPosition();
      this.recs[i].x = e.target.x();
      this.recs[i].y = e.target.y();
      this.isDragging = false;
      this.disableSaveButton = false;
    },

    pixelateVideo() {
      this.detachTransformer();
      this.isRegionSelected = false;
      this.selectedIndex = null;
      this.isPixelate = true;
      this.isPixelate ? (this.pixelating = true) : (this.pixelating = false);
      if (!this.pixelating) {
        return;
      }
      const pos = this.$refs.stage.getNode().getPointerPosition();
      let index = this.recs.length;
      this.recs.push({
        x: 275,
        y: 215,
        w: 50,
        h: 50,
        id: index.toString(),
        draggable: false,
        device_settings: this.deviceSetting
      });
      this.newRecs = [...this.newRecs, index];
      this.selectedIndex = index;
      this.isRegionSelected = true;
      this.disableSaveButton = false;
    },
    handleMouseUp() {
      this.pixelating = false;
      this.isPixelate = false;
    },
    setRecs(element) {
      this.recs = element;
    },
    handleMouseMove(event) {
      // no drawing - skipping
      if (!this.pixelating) {
        return;
      }
      const point = this.$refs.stage.getNode().getPointerPosition();
      // handle  rectangle part
      let curRec = this.recs[this.recs.length - 1];
      curRec.w = point.x - curRec.x;
      curRec.h = point.y - curRec.y;
      let rec = curRec;
      let x = Math.min(rec.x, rec.x + rec.w);
      let y = Math.min(rec.y, rec.y + rec.h);
      let width = Math.abs(rec.w);
      let height = Math.abs(rec.w);
    },

    async removeRegion(item, index) {
      if (this.newRecs && this.newRecs.includes(index)) {
        this.recs.splice(index, 1);
        this.newRecs.splice(index, 1);
        this.detachTransformer();
        return;
      }

      let id = this.recs[index].id;
      this.recs.splice(index, 1);
      await DeviceServices.deleteBlurRegion(id);
      this.isRegionSelected = false;
      this.selectedIndex = null;
      this.isDragging = false;
      this.detachTransformer();
    }
  }
};
</script>
<style scoped>
.video-container {
  height: 600px;
  display: flex;
  align-items: center;
  position: relative;
}
.green-region {
  font-weight: bolder;
  background-color: rgb(210, 248, 210);
}
.disabled {
  pointer-events: none; /* Disable interaction with the div */
  opacity: 0.7; /* Reduce the opacity to visually disable the content */
}

.spinner-overlay {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  /* Semi-transparent background for the overlay */
  /* background: rgba(
    255,
    255,
    255,
    0.8
  );  */
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1;  */
  text-align: center;
  flex-direction: column;
}
.spinner-text {
  margin-top: 10px; /* Add some spacing between the spinner and text */
}
</style>
