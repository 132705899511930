<template>
  <!-- Take Screenshot Modal -->
  <a-modal
    id="devices-schedule-task-model"
    v-model:visible="showTakeScreenshotModal"
    title="Screenshots"
    width="1000px"
    centered
    :mask-closable="false"
    :ok-button-props="{
      id: 'dm-schedule-task-ok-btn',
      disabled: !item.serviceStatus,
    }"
    ok-text="Take Screenshot"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    :confirm-loading="isLoadingForScreenshot"
    @ok="takeScreenshot"
    @cancel="closeDeviceScreenshotModal"
  >
    <device-screenshot ref="DeviceScreenshot" :item="item" />
    <!-- <ScheduleTask
      ref="ScheduleTask"
      :showScheduleTaskModal="showScheduleTaskModal"
      :record="item"
      :deviceDetails="deviceDetails"
    /> -->
  </a-modal>
  <!-- Stream Video Modal -->
  <a-modal
    id="devices-schedule-task-model"
    v-model:visible="showScheduleTaskModal"
    title="Schedule"
    destroy-on-close
    :mask-closable="false"
    :ok-button-props="{ id: 'dm-schedule-task-ok-btn' }"
    ok-text="Schedule"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    @ok="handleScheduleForSelectedType"
    @cancel="closeScheduleTaskModal"
  >
    <ScheduleTask
      ref="ScheduleTask"
      :show-schedule-task-modal="showScheduleTaskModal"
      :record="item"
      :device-details="deviceDetails"
    />
  </a-modal>
  <a-modal
    v-model:visible="showStreamModal"
    :footer="null"
    width="1000px"
    centered
    destroy-on-close
    :mask-closable="false"
    :title="item.title"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="handleStreamModalClose"
  >
    <div id="stream-modal-body" class="w-100">
      <VideoStream
        v-if="streamSignal === true"
        :serial-number="item.Serial_number"
        :device-setting="item.id"
        :device-settings="item"
        @closeModal="showStreamModal = false"
      />

      <screen-shot
        v-else
        :snap-shot="snapShot"
        :device-setting-url="deviceSettingUrl"
        :serial-number="serialNumber"
        :is-default-image="isDefaultImage"
        :task_list="task_list"
        :device-cropping-params="deviceSetting.cropping_params"
        :device-id="deviceSetting.id"
        :device-camera-mode="deviceSetting.mode"
        :task-cropping-params="currentTask.cropping_params"
        :current-selected-task="currentTask.id"
        @setCameraMode="setCurrentCameraMode"
        @changeCroppingSignal="(val) => (croppingSignal = val)"
        @setControlImageTime="setControlImageTime"
      />
    </div>
  </a-modal>

  <!-- <a-modal
    v-model:visible="showTowerLightModal"
    :footer="null"
    width="1000px"
    centered
    destroy-on-close
    title="Tower Light Configuration"
    @cancel="hidePateliteTower"
  >
  <signal-tower :device="deviceSetting" @hidePateliteTower="hidePateliteTower" />
  </a-modal> -->

  <!-- starred device -->
  <td>
    <a-rate
      style="margin-top: -0.3em; font-size: 2em"
      :value="item.starred ? 1 : 0"
      :count="1"
      class="ml-2"
      @change="handleChangeStarred"
    />
  </td>

  <!--  "Serial_number",-->
  <td class="black-color list-cell d-flex flex-column align-items-center">
    <a-popover arrow hover placement="top">
      <div v-if="!isEditDeviceName" class="display-name">
        {{
          item.title.length > 20 ? item.title.slice(0, 20) + '...' : item.title
        }}
      </div>
      <template #content>
        <div v-for="row in deviceHoverInfo" :key="row.key">
          <span v-if="row.key === 'Organization'">
            {{ row.title }} :
            <a-tag color="processing">
              {{ item[row.key].Org_name || organization }}
            </a-tag>
          </span>
          <span v-else-if="row.key === 'version_no'">
            {{ row.title }} :
            <a-tag color="processing">{{ version_no || '-' }}</a-tag>
          </span>
          <span v-else>
            {{ row.title }} :
            <a-tag color="processing">{{ item[row.key] || '-' }}</a-tag>
          </span>
        </div>
      </template>
    </a-popover>

    <div v-if="isEditDeviceName" style="display: flex">
      <a-input
        v-model:value="deviceDetails.display_name"
        placeholder="Device Name"
        size="small"
        style="width: 120px"
      />
      <a-button class="ml-1" size="small" @click="handleSaveDeviceName">
        Update Name
      </a-button>
      <a-button class="ml-1" size="small" @click="isEditDeviceName = false">
        Cancel
      </a-button>
    </div>
    <div v-if="isEditEmail" style="display: flex">
      <a-input
        v-model:value="deviceDetails.email"
        placeholder="Email Address"
        size="small"
        style="width: 120px"
      />
      <a-button class="ml-1" size="small" @click="handleSaveEmail">
        Update Email
      </a-button>
      <a-button class="ml-1" size="small" @click="isEditEmail = false">
        Cancel
      </a-button>
    </div>
    <a-space>
      <a-tooltip v-if="isDev()" title="Take Screenshot">
        <i
          class="bi bi-camera image-icon"
          @click="toggleDeviceScreenshotModal"
        />
      </a-tooltip>

      <a-tooltip v-if="!showShutDown && showStart" title="Start Device">
        <i
          class="bi bi-power image-icon"
          @click="pathfinderService(item, 'start_software')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="item.serviceStatus && item.active && showShutDown"
        title="Stop Device"
      >
        <i
          class="bi bi-stop-circle image-icon"
          :class="{ disabled: !item.active }"
          @click="pathfinderService(item, 'shutdown_software')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="item.serviceStatus && item.active && showShutDown"
        title="Restart Device"
      >
        <i
          class="bi bi-arrow-counterclockwise image-icon"
          :class="{ disabled: !item.active }"
          @click="pathfinderService(item, 'restart_software')"
        />
      </a-tooltip>

      <a-tooltip title="Show Live stream">
        <i
          class="bi bi-cast image-icon"
          :class="{ disabled: !isActive || isEditDeviceName || isEditEmail }"
          @click="showStream"
        />
      </a-tooltip>

      <a-tooltip title="Edit device name">
        <i
          class="bi bi-pencil-square image-icon"
          :class="{ disabled: !isActive || isEditDeviceName }"
          @click="handleSaveDeviceName"
        />
        <!-- <edit-outlined :class="{ disabled: !isActive || isEdit }" @click="handleSaveDeviceName"/> -->
      </a-tooltip>

      <a-tooltip title="Edit Email">
        <mail-outlined
          :class="{ disabled: !isActive || isEditEmail }"
          :style="{ color: isActive ? '#2391ff' : 'lightgray' }"
          @click="handleSaveEmail"
        />
      </a-tooltip>
    </a-space>

    <small v-if="deviceActivity && isActive" class="mt-2">
      {{ deviceActivity }}
    </small>
    <a-tooltip :title="getDeviceStatus || ''">
      <a-tag :color="deviceStatusColor" class="mt-2 download-upload-details">
        {{ getDeviceStatus }}
      </a-tag>
    </a-tooltip>

    <!-- Downloading/Uploading Details -->
    <a-tag v-if="dataTransferDetails" class="mt-1 text-center">
      <template #icon>
        <span v-if="dataTransferDetails?.progress > 0">
          {{ dataTransferDetails.progress + '%' }}
        </span>
      </template>
      &nbsp;{{ dataTransferDetails.transferType }}
    </a-tag>

    <a-tooltip :title="dataTransferDetails.name">
      <small v-if="dataTransferDetails" class="download-upload-details">
        {{ dataTransferDetails.name }}
      </small>
    </a-tooltip>
  </td>

  <!--    manage device-->
  <td>
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}3`"
        v-model="deviceDetails.manage"
        type="checkbox"
        class="custom-control-input"
        :disabled="!isActive"
      />
      <label
        class="custom-control-label"
        :class="{
          'text-secondary': !isActive,
          'text-dark': isActive,
        }"
        :for="`${item.id}3`"
      >
        {{ tableRowColumns[3] }}
      </label>
    </div>
  </td>

  <!-- record controls -->
  <td class="black-color list-cell">
    <!--    record shift-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}3${index}`"
        v-model="deviceDetails.record_shift"
        type="checkbox"
        class="custom-control-input"
        :disabled="
          isControlsDisabled ||
          deviceDetails.isInferenceRunning ||
          deviceDetails.recordInference
        "
      />
      <label
        class="custom-control-label"
        :for="`${item.id}3${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][3] }}
      </label>
    </div>

    <!--    record inference-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}1${index}`"
        v-model="deviceDetails.recordInference"
        type="checkbox"
        class="custom-control-input"
        :disabled="
          isControlsDisabled ||
          deviceDetails.record_shift ||
          !deviceDetails.isInferenceRunning
        "
      />
      <label
        class="custom-control-label"
        :for="`${item.id}1${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][1] }}
      </label>
    </div>
    <div>
      <a-button
        block
        class="mt-1"
        size="middle"
        type="primary"
        :disabled="!isActive"
        @click="toggleScheduleTaskModal"
      >
        Scheduler
      </a-button>
    </div>
  </td>

  <!-- "Device controls" -->
  <td class="black-color list-cell">
    <!--  "Projector"-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}8${index}`"
        v-model="deviceDetails.projector_enable"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled || deviceSetting.length === 0"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}8${index}`"
        :class="{
          'text-secondary': deviceSetting.length === 0,
        }"
      >
        {{ tableRowColumns[4][8] }}
      </label>
    </div>

    <!--    blurFace-->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}0${index}`"
        v-model="deviceDetails.blurFace"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :class="{ 'text-secondary': !isActive }"
        :for="`${item.id}0${index}`"
      >
        {{ tableRowColumns[4][0] }}
      </label>
    </div>

    <!-- enable translation -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}6${index}`"
        v-model="deviceDetails.is_translation_enabled"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}6${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][6] }}
      </label>
    </div>

    <!-- Box Visualization -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}9${index}`"
        v-model="deviceDetails.box_visualization"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled || deviceDetails.show_step_images"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}9${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][9] }}
      </label>
    </div>
    <!-- Show Previous Cycle Errors -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}10${index}`"
        v-model="deviceDetails.show_previous_cycle_errors"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}10${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][10] }}
      </label>
    </div>

    <!-- Enable Vieo -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}11${index}`"
        v-model="deviceDetails.enable_video"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}11${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][11] }}
      </label>
    </div>

    <!-- Show Step Images -->
    <div class="custom-control custom-switch" v-if="showStepImageControl">
      <input
        :id="`${item.id}13${index}`"
        v-model="deviceDetails.show_step_images"
        type="checkbox"
        class="custom-control-input"
        :disabled="
          isControlsDisabled ||
          !deviceDetails.isInferenceRunning ||
          deviceDetails.record_shift
        "
      />
      <label
        class="custom-control-label"
        :for="`${item.id}13${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][13] }}
      </label>
    </div>

    <!-- Show/Hide Task Definition -->
    <div class="custom-control custom-switch" v-if="showTaskStepsControl">
      <input
        :id="`${item.id}14${index}`"
        v-model="deviceDetails.show_task_definition"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}14${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][14] }}
      </label>
    </div>

    <!-- Enable Auto Updates -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}12${index}`"
        v-model="deviceDetails.auto_update_enabled"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :for="`${item.id}12${index}`"
        :class="{ 'text-secondary': !isActive }"
      >
        {{ tableRowColumns[4][12] }}
      </label>
    </div>

    <!-- Enter Cap FPS -->
    <div class="col-xs-3" v-if="showFPSCapControl">
      <input
        :id="`${item.id}15${index}`"
        v-model="deviceDetails.fps_cap"
        type="number"
        :min="10"
        :max="30"
        placeholder="FPS"
        style="
          margin-top: 5%;
          margin-right: 5%;
          border-radius: 15%;
          border-color: gainsboro;
        "
        :class="{ disabled: !isActive }"
      />
      <label
        :for="`${item.id}15${index}`"
        :class="{ 'text-secondary': !isActive }"
        style="white-space: nowrap; font-size: 12px"
      >
        {{ tableRowColumns[4][15] }}
      </label>
    </div>
  </td>

  <!-- camera controls -->
  <td class="black-color list-cell">
    <!-- Depth Camera -->
    <div class="custom-control custom-switch">
      <input
        :id="`${item.id}7${index}`"
        v-model="deviceDetails.depth_cam_enabled"
        type="checkbox"
        class="custom-control-input"
        :disabled="isControlsDisabled"
      />
      <label
        class="custom-control-label"
        :class="{ 'text-secondary': !isActive }"
        :for="`${item.id}7${index}`"
      >
        {{ tableRowColumns[4][7] }}
      </label>
    </div>

    <a-tooltip>
      <!--    multi view-->
      <div class="custom-control custom-switch">
        <input
          :id="`${item.id}4${index}`"
          v-model="deviceDetails.multi_view"
          type="checkbox"
          class="custom-control-input"
          :disabled="
            isControlsDisabled ||
            deviceDetails.recordInference ||
            deviceDetails.record_shift
          "
        />
        <label
          class="custom-control-label"
          :for="`${item.id}4${index}`"
          :class="{ 'text-secondary': !isActive }"
        >
          {{ tableRowColumns[4][4] }}
        </label>
      </div>
      <!--    multi cam-->
      <div class="custom-control custom-switch">
        <input
          :id="`${item.id}5${index}`"
          v-model="deviceDetails.remote_cam_enabled"
          type="checkbox"
          class="custom-control-input"
          :disabled="
            isControlsDisabled ||
            deviceDetails.recordInference ||
            deviceDetails.record_shift
          "
        />
        <label
          class="custom-control-label"
          :for="`${item.id}5${index}`"
          :class="{ 'text-secondary': !isActive }"
        >
          {{ tableRowColumns[4][5] }}
        </label>
      </div>

      <template
        v-if="deviceDetails.record_shift || deviceDetails.recordInference"
        #title
      >
        Disable all recordings first
      </template>
    </a-tooltip>
  </td>

  <!--  "Current_task_running",-->
  <td class="black-color list-cell pl-4" style="width: 200px">
    <div class="black-color list-cell my-2">
      <label>
        Current Operation:

        <a-tag
          v-if="deviceDetails.manage"
          :color="isActive && item.Task ? 'processing' : 'default'"
        >
          <a-tooltip :title="item.Task ? showTaskName(item.Task) : 'None'">
            {{
              item.Task ? showTaskName(item.Task).slice(0, 15) + '...' : 'None'
            }}
          </a-tooltip>
        </a-tag>
      </label>
    </div>
    <div
      class="black-color list-cell my-2"
      style="position: relative"
      id="area"
    >
      <a-select
        v-model:value="deviceDetails.Task"
        placeholder="Select task"
        style="width: 240px"
        :loading="isLoading || isTaskUpdating"
        show-search
        :options="taskOptions"
        :filter-option="filterOption"
        :disabled="isControlsDisabled"
        :getPopupContainer="getPopupContainer"
      />
    </div>
  </td>

  <!--  "inferenceControls",-->
  <td>
    <a-space class="w-100" direction="vertical" align="center">
      <a-button
        type="primary"
        ghost
        :disabled="
          isControlsDisabled ||
          deviceDetails.isInferenceRunning ||
          deviceDetails.record_shift
        "
        @click="startInference"
      >
        Start Task
      </a-button>
      <a-button
        type="primary"
        ghost
        :disabled="isControlsDisabled || !deviceDetails.isInferenceRunning"
        @click="stopInference"
      >
        Stop Task
      </a-button>
    </a-space>
  </td>

  <!--  "updateDevice"-->
  <td>
    <a-space class="w-100" direction="vertical">
      <a-button
        block
        type="primary"
        :loading="isApplyingChanges"
        :disabled="!isActive"
        @click="updateDevice"
      >
        Apply To Device
      </a-button>
      <a-button
        block
        type="primary"
        :disabled="!isActive"
        @click="switchCamera(item)"
      >
        Flip Camera
      </a-button>
      <a-button
        block
        type="primary"
        :disabled="!isActive"
        @click="fetchAnalytics(item)"
      >
        Pull Analytics
      </a-button>
      <a-popconfirm
        title="Are you sure? you want to Delete this device?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="deleteDevice()"
      >
        <a-button :loading="isDeletingDevice" type="primary" block danger>
          Delete Device
        </a-button>
      </a-popconfirm>
    </a-space>
  </td>
</template>

<script>
import httpClient from 'src/service/httpClient';
import DeviceService from 'src/services/device.js';
import dateHelper from '../../../shared/Helpers/dateHelper';

import VideoStream from './VideoStream.vue';
import ScreenShot from './ScreenShot.vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { getSortedTask } from 'src/utils/task';
import spaceMixin from 'src/mixins/handleSpace';
import { mapGetters } from 'vuex';
import ScheduleTask from './ScheduleTask.vue';

import fcm from 'src/services/fcm';
import DeviceScreenshot from './DeviceScreenshot.vue';
import featureAuthorization from 'src/mixins/featureAuthorization';

export default {
  name: 'AssignedDevice',
  components: {
    VideoStream,
    ScreenShot,
    ScheduleTask,
    DeviceScreenshot,
    MailOutlined,
  },
  mixins: [spaceMixin, featureAuthorization],
  inject: ['toast', 'isDev'],
  props: [
    'item',
    'index',
    'tableRowColumns',
    'deviceHoverInfo',
    'isLoading',
    'task_list',
    'trainedTasks',
    'activeDevices',
    'allDevices',
    'taskIdToTaskNameMap',
  ],
  emits: [
    'unAssignDeviceFromDept',
    'updateDeviceList',
    'deleteDeviceFromDeviceList',
    'isModalOpen',
    'closeModal',
  ],

  data() {
    return {
      showStart: false,
      showShutDown: false,
      deviceDetails: {},
      isEditDeviceName: false,
      isEditEmail: false,
      deviceSetting: {},
      oldDeviceSetting: {},
      currentTask: {},
      isTaskUpdating: false,
      pollingTime: 6000,
      polling: null,
      start_time: '00:00:00',
      end_time: '23:59:59',
      tempDeviceSetting: {},
      isRequestResolved: true,
      date: new Date(),
      currentTime: null,
      currentFormattedDate: null,
      analytics_start_time: '00:00:00',
      analytics_end_time: '23:59:59',
      showMoreOptions: false,
      // ================ device status ================
      serialNumber: '',
      deviceSettingUrl: '',
      image: '',
      isDeviceStatusPolled: false,
      deviceStatusPolling: null,
      deviceStatus: ['Inactive'],
      isDownloading: false,
      isUploading: false,
      progress: 0,
      deviceCroppingParams: {},
      controlTime: '',
      showTowerLightModal: false,
      // ========= snapshot
      showStreamModal: false,
      streamSignalPolling: null,
      streamSignal: false,
      croppingSignal: false,
      currentCameraMode: '',
      isDefaultImage: false,
      snapShot: null,
      downloadUploadDetails: '',
      openRestartDeviceDialog: true,
      openUnassignDialog: true,
      deviceToUnassign: '',
      isStreamLoading: false,
      rtcServerThreadStatus: false,
      projector: false,
      isUnmounted: false,
      isApplyingChanges: false,
      version_no: '',
      isModelToastShown: false,
      oldDisplayName: '',
      oldEmail: '',
      showScheduleTaskModal: false,
      showTakeScreenshotModal: false,
      isLoadingForScreenshot: false,
      isDeletingDevice: false,
      // Feature based states
      showStepImageControl: false,
      showTaskStepsControl: false,
      showFPSCapControl: false,
    };
  },

  computed: {
    ...mapGetters(['organization']),
    getDeviceStatus() {
      const {
        is_shift_managed,
        record_shift,
        isInferenceRunning,
        inference,
        recording,
        task,
        active,
      } = this.item;
      if (is_shift_managed) {
        if (inference || recording)
          return task ? task : this.item?.Task?.taskName;
      }
      if (record_shift || isInferenceRunning)
        return task ? task : this.item?.Task?.taskName;
      return active ? 'Active' : 'Inactive';
    },

    deviceStatusColor() {
      return {
        Active: 'processing',
        Inactive: 'default',
      }[this.getDeviceStatus];
    },

    deviceActivity() {
      const {
        is_shift_managed,
        record_shift,

        inference,
        recording,
      } = this.item;
      if (is_shift_managed) {
        if (inference) return 'Inferencing for ';
        if (recording) return 'Recording for ';
      }
      if (inference) return 'Inferencing for ';
      if (record_shift) return 'Recording for ';
      return '';
    },

    dataTransferDetails() {
      const { downloading, uploading } = this.item;
      if (downloading)
        return this.getDataTransferTypeDetails(downloading, 'Downloading');

      if (uploading)
        return this.getDataTransferTypeDetails(uploading, 'Uploading');

      return false;
    },

    taskOptions() {
      return getSortedTask(this.task_list);
    },

    isActive() {
      return !!this.item.active;
    },

    isControlsDisabled() {
      return !this.isActive || !this.deviceDetails.manage;
    },
  },

  watch: {
    item: {
      handler: function (newVal) {
        this.setDeviceStatusState(newVal);
        // this.getDeviceSettings();
      },
      deep: true,
    },
  },
  async created() {
    window.addEventListener('beforeunload', this.resetPolling);
    this.showStepImageControl = await this.computeShow('SHOW STEP IMAGES');
    this.showTaskStepsControl = await this.computeShow('SHOW_TASK_DEFINITION');
    this.showFPSCapControl = await this.computeShow('FPS_THROTTLING');
    if (this.item.active) {
      const serialNumberToSearch = this.item.Serial_number;
      const matchingDevice = this.allDevices.find(
        (device) => device.Serial_number === serialNumberToSearch
      );

      if (this.item.active && ('Task' in this.item || this.item.Task)) {
        this.deviceDetails = {
          ...this.item,
          ...(matchingDevice || {}),
        };
      }
    } else {
      this.deviceDetails = {
        ...this.item,
        shift_start_time: this.item.shift_start_time
          ?.split('T')
          .pop()
          .replace('Z', ''),
        shift_end_time: this.item.shift_end_time
          ?.split('T')
          .pop()
          .replace('Z', ''),
      };
    }

    if (
      this.deviceDetails.Task &&
      typeof this.deviceDetails.Task === 'object'
    ) {
      const tempTask = this.deviceDetails.Task.id;
      delete this.deviceDetails.Task;
      this.deviceDetails['Task'] = tempTask;
    }
    if (this.deviceDetails.active) {
      this.showShutDown = true;
    }
    if (this.deviceDetails.serviceStatus) {
      this.showStart = true;
    }
  },

  beforeUnmount() {
    this.isUnmounted = true;
    this.resetPolling();
  },

  methods: {
    showTaskName(task) {
      if (task && typeof task === 'object') {
        return task.taskName;
      }
      return this.taskIdToTaskNameMap[task]
        ? this.taskIdToTaskNameMap[task]
        : '';
    },
    async takeScreenshot() {
      this.isLoadingForScreenshot = true;

      // Call pathfinderService with 'take_screenshot' signal
      await this.pathfinderService(this.item, 'take_screenshot');

      // Reset loading state
      this.isLoadingForScreenshot = false;
    },
    getPopupContainer() {
      let area = document.getElementById('area');
      return area;
    },
    checkIfTimeSelected() {
      !this.$refs.DeviceScreenshot.selectedTime ? true : false;
    },
    async pathfinderService(record, signal) {
      if (signal === 'take_screenshot') this.isLoadingForScreenshot = true;
      if (signal === 'shutdown_software') {
        this.showStart = true;
        this.showShutDown = false;
      }
      const payload = {
        data: {},
        type: signal,
        device_id: record.Serial_number + '-service',
        //this.org-MAC-service
      };
      const [error] = await fcm.sendMessageToSQS(payload, false);
      this.isLoadingForScreenshot = false;
      if (error) throw 'Error while sending set telemetry request';
    },
    async fetchAnalytics(record) {
      const payload = {
        data: { telemetry: true },
        type: 'set_telemetry',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: localStorage.getItem('organization'),
        device_id: record.Serial_number,
        //this.org-MAC-service
      };
      const [error] = await fcm.sendMessageToRabbitMQ(payload, false);
      if (error) throw 'Error while sending set telemetry request';
    },
    closeScheduleTaskModal() {
      this.showScheduleTaskModal = false;
      this.$emit('closeModal', false);
    },
    toggleScheduleTaskModal() {
      this.showScheduleTaskModal = !this.showScheduleTaskModal;
      this.$emit('isModalOpen', true);
    },
    closeDeviceScreenshotModal() {
      this.showTakeScreenshotModal = false;
      this.$emit('closeModal', false);
    },
    toggleDeviceScreenshotModal() {
      this.showTakeScreenshotModal = !this.showTakeScreenshotModal;
      this.$emit('isModalOpen', true);
    },
    getDataTransferTypeDetails(typeObj, type) {
      return {
        name: typeObj.name,
        progress: typeObj.progress,
        transferType: type,
      };
    },
    restartDeviceDialog() {
      this.openRestartDeviceDialog = !this.openRestartDeviceDialog;
    },

    // async restartDevice() {
    //   const date = new Date();
    //   const date_today = dateHelper.getFormattedDate(date) + 'T';
    //   const time_now =
    //     date.getHours() +
    //     ':' +
    //     date.getMinutes() +
    //     ':' +
    //     date.getSeconds() +
    //     'Z';
    //   const time_to_restart = date_today + time_now;
    //   await this.SendDeviceUpdate({ restart_time: time_to_restart });
    // },

    getDisplayName() {
      return this.item[this.tableRowColumns[7]]
        ? this.item[this.tableRowColumns[7]]
        : this.item[this.tableRowColumns[0]]
        ? this.item[this.tableRowColumns[0]]
        : '-';
    },

    getDeviceSettingUrl() {
      return (
        'device/' +
        this.organization +
        '/device_settings/' +
        this.item.Serial_number +
        '/'
      );
    },

    getDeviveSettingUrl(setting) {
      if (!setting) return '';
      return `device/device_status/?Serial_number=${setting.Device.Serial_number}`;
    },

    async getDeviceSettings() {
      if (this.isUnmounted) return;
      // if (!this.activeDevices.includes(this.item.Serial_number)) {
      //   if (this.showStreamModal){
      //     this.toast.info('Your device has been deactivated!');
      //     this.getDeviceStatus();
      //   }
      //   this.showStreamModal = false;
      //   return;
      // }
      // if (!this.activeDevices.includes(this.item.Serial_number)) return;
      this.isDeviceStatusPolled = true;
      let deviceSettingsURL = this.getDeviceSettingUrl();
      const list = await httpClient.get(deviceSettingsURL, false);
      const [deviceSetting] = list;
      this.deviceSettingUrl = this.getDeviveSettingUrl(deviceSetting);
      // await this.getDeviceStatus();

      if (deviceSetting) {
        if (
          JSON.stringify(this.tempDeviceSetting) !==
          JSON.stringify(deviceSetting)
        ) {
          this.deviceSetting = JSON.parse(JSON.stringify(deviceSetting));

          this.currentCameraMode = this.deviceSetting?.mode;
          if (this.deviceSetting.shift_start_time) {
            this.start_time = this.deviceSetting.shift_start_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.shift_end_time) {
            this.end_time = this.deviceSetting.shift_end_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.analytics_start_time) {
            this.analytics_start_time = this.deviceSetting.analytics_start_time
              .split('T')[1]
              .slice(0, -1);
          }
          if (this.deviceSetting.analytics_end_time) {
            this.analytics_end_time = this.deviceSetting.analytics_end_time
              .split('T')[1]
              .slice(0, -1);
          }
          this.tempDeviceSetting = { ...deviceSetting };

          if (this.deviceSetting.Task) {
            this.currentTask = this.deviceSetting.Task;
          }
          this.saveCopyOfDeviceSetting();
        }
      }
    },

    async getImage() {
      let deviceSettingsURL = this.getDeviceSettingUrl();

      const list = await httpClient.get(deviceSettingsURL, false);
      const [deviceSetting] = list;
      this.deviceSettingUrl = this.getDeviveSettingUrl(deviceSetting);
      await this.getDeviceStatus();
      return this.snapShot;
    },

    saveCopyOfDeviceSetting() {
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      this.oldDeviceSetting = {
        blurFace: this.deviceSetting.blurFace,
        isInferenceRunning: this.deviceSetting.isInferenceRunning,
        manage: this.deviceSetting.manage,
        recordInference: this.deviceSetting.recordInference,
        is_shift_managed: this.deviceSetting.is_shift_managed,
        record_shift: this.deviceSetting.record_shift,
        shift_start_time: this.currentFormattedDate + 'T' + this.start_time,
        shift_end_time: this.currentFormattedDate + 'T' + this.end_time,
        control_image_time:
          this.currentFormattedDate + 'T' + (this.controlTime || '00:00:00Z'),
        mode: this.currentCameraMode,
        multi_view: this.deviceSetting.multi_view,
        is_translation_enabled: this.deviceSetting.is_translation_enabled,
        remote_cam_enabled: this.deviceSetting.remote_cam_enabled,
        analytics_start_time:
          this.currentFormattedDate + 'T' + this.analytics_start_time,
        analytics_end_time:
          this.currentFormattedDate + 'T' + this.analytics_end_time,
        projector_enable: this.deviceSetting.projector_enable,
        depth_cam_enabled: this.deviceSetting.depth_cam_enabled,
      };
    },

    setControlImageTime() {
      const date = new Date();
      this.controlTime =
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        'Z';
      this.updateDevice();
    },

    // async getDeviceStatus() {
    //   if (this.isUnmounted || !this.deviceSettingUrl) return;

    //   const res = await httpClient.getData(this.deviceSettingUrl, false);
    //   if (res?.device_status?.image) {
    //     this.image = res.device_status.image;
    //   }
    //   this.isDeviceStatusPolled = false;
    //   const { device_status } = res;
    //   if (!device_status) {
    //     clearTimeout(this.deviceStatusPolling);
    //     return;
    //   }
    //   this.setDeviceStatusState(device_status);
    // },

    async sendSignalToStream() {
      const data = {
        [`${this.serialNumber}stream`]: this.streamSignal,
        [`${this.serialNumber}is_cropping`]: this.croppingSignal,
        Serial_number: this.serialNumber,
      };
      await httpClient.post(this.deviceSettingUrl, data, false, false, false);
    },

    setDeviceStatusState(device_status) {
      const {
        downloading,
        active,
        uploading,
        inference,
        recording,
        task,
        image,
        answer,
        version_no,
        rtc_thread_status,
        is_projector_attached,
      } = device_status;
      if (inference) {
        this.deviceStatus = ['Inferencing for ', task];
      } else if (recording) {
        this.deviceStatus = ['Recording for ', task];
      } else if (active) {
        this.deviceStatus = ['Active'];
      } else {
        this.deviceStatus = ['Inactive'];
      }
      if (version_no) {
        this.version_no = version_no;
      }
      this.isDownloading = !!downloading;
      this.isUploading = !!uploading;
      this.snapShot = image ? 'data:image/jpg;base64, ' + image : false;
      this.isDefaultImage = answer;
      let progress, name;
      if (downloading) {
        progress = downloading.progress;
        name = downloading.name;
        if (name.endsWith('.zip') && !this.isModelToastShown) {
          this.isModelToastShown = true;
          this.toast.info(
            `We are downloading the model on your device. This will take several minutes.`
          );
        }
      }
      if (uploading) {
        progress = uploading.progress;
        name = uploading.name;
      }
      this.downloadUploadDetails = name;
      this.progress = progress ? progress : 0;

      if (rtc_thread_status) {
        this.rtcServerThreadStatus = rtc_thread_status;
      }

      this.projector = is_projector_attached;

    },

    setCurrentCameraMode(value) {
      this.deviceDetails.mode = value;
      this.updateDevice();
    },

    isTrainedTask(taskId = this.item.Task) {
      return !this.trainedTasks.map(({ task }) => task.id).includes(taskId);
    },

    startInference() {
      if (this.isTrainedTask()) {
        this.toast.info('Cannot run inference on Untrained task!');
        return;
      }
      this.deviceDetails.isInferenceRunning = true;
      this.deviceDetails.recordInference = true;
    },

    stopInference() {
      this.deviceDetails.isInferenceRunning = false;
      this.deviceDetails.recordInference = false;
    },

    isTaskChanged() {
      if (typeof this.deviceDetails.Task == 'object') {
        return this.item?.Task?.id !== this.deviceDetails.Task?.id;
      }
      if (typeof this.item.Task == 'object') {
        return this.item.Task?.id !== this.deviceDetails?.Task;
      }
      return this.item?.Task !== this.deviceDetails?.Task;
    },

    validateRecordShift() {
      const { record_shift, isInferenceRunning } = this.deviceDetails;
      console.log(record_shift, isInferenceRunning, this.isTaskChanged());
      // if ((record_shift && isInferenceRunning) || this.isTaskChanged()) {
      //   this.renderErrorToast(
      //     'You cannot start inference, manage shift or change task when recording shift.'
      //   );
      //   return false;
      // }
      // return true;
      if ((record_shift || isInferenceRunning) && this.isTaskChanged()) {
        this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
        return false;
      }

      return true;
    },

    validateRecordInference() {
      const {
        is_shift_managed,
        recordInferenceFlipped,
        isInferenceRunning,
        isShiftTime,
      } = this.getDeviceStates();

      if (is_shift_managed && recordInferenceFlipped && isShiftTime)
        return this.renderErrorToast(
          'Recording cannot be toggled while Manage Shift is enabled. Please disable Manage Shift first.'
        );

      if (
        recordInferenceFlipped &&
        isInferenceRunning &&
        !this.deviceDetails.is_shift_managed
      )
        return this.renderErrorToast(
          'Recording cannot be toggled while Inference is running. Please stop Inference first.'
        );

      return true;
    },

    getDeviceStates() {
      return {
        is_shift_managed: this.isDeviceOn('is_shift_managed'),
        recordInferenceFlipped: this.isControlFlipped('recordInference'),
        isInferenceRunning: this.isDeviceOn('isInferenceRunning'),
        isShiftTime: this.isShiftTime(),
      };
    },

    renderErrorToast(message) {
      const timeout = { timeout: 2000 };
      this.toast.info(message, timeout);
      return false;
    },

    isDeviceOn(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return oldS === true && newS === true;
    },

    isControlFlipped(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return !(oldS === newS);
    },

    isShiftTime() {
      if (!this.item.shift_start_time || !this.item.shift_end_time) {
        return false;
      }
      let prevStartShiftTime = new Date(
        this.item.shift_start_time.slice(0, -1)
      ).getTime();
      let prevEndShiftTime = new Date(
        this.item.shift_end_time.slice(0, -1)
      ).getTime();
      let currentTime = new Date().getTime();
      return prevStartShiftTime < currentTime && currentTime < prevEndShiftTime;
    },

    getStartEndTIme() {
      const { currentFormattedDate } = this;
      const { shift_start_time, shift_end_time } = this.deviceDetails;
      return {
        shift_start_time: shift_start_time
          ? currentFormattedDate + 'T' + shift_start_time
          : null,
        shift_end_time: shift_end_time
          ? currentFormattedDate + 'T' + shift_end_time
          : null,
      };
    },

    setupUpdateDeviceSetting() {
      this.date = new Date();
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      if (!this.controlTime) this.controlTime = '00:00:00Z';
      let data = {
        blurFace: this.deviceDetails.blurFace,
        depth_cam_enabled: this.deviceDetails.depth_cam_enabled,
        isInferenceRunning: this.deviceDetails.isInferenceRunning,
        manage: this.deviceDetails.manage,
        recordInference: this.deviceDetails.recordInference,
        is_shift_managed: this.deviceDetails.is_shift_managed,
        record_shift: this.deviceDetails.record_shift,
        // shift_start_time: this.currentFormattedDate + 'T' + this.start_time,
        // shift_end_time: this.currentFormattedDate + 'T' + this.end_time,
        control_image_time: this.currentFormattedDate + 'T' + this.controlTime,
        mode: this.currentCameraMode,
        multi_view: this.deviceDetails.multi_view,
        is_translation_enabled: this.deviceDetails.is_translation_enabled,
        // analytics_start_time:
        //   this.currentFormattedDate + 'T' + this.analytics_start_time,
        // analytics_end_time:
        //   this.currentFormattedDate + 'T' + this.analytics_end_time,
        projector_enable: this.deviceDetails.projector_enable,
        remote_cam_enabled: this.deviceDetails.remote_cam_enabled,
        day_of_week_for_recording: this.deviceDetails.day_of_week_for_recording,
        box_visualization: this.deviceDetails.box_visualization,
        show_previous_cycle_errors:
          this.deviceDetails.show_previous_cycle_errors,
        enable_video: this.deviceDetails.enable_video,
        show_step_images: this.deviceDetails.show_step_images,
        show_task_definition: this.deviceDetails.show_task_definition,
        auto_update_enabled: this.deviceDetails.auto_update_enabled,
        upload_bad_cycle_videos: this.deviceDetails.upload_bad_cycle_videos,
        fps_cap: this.deviceDetails.fps_cap,
      };

      // data = {
      //   ...data,
      //   ...this.getStartEndTIme(),
      //   mode: this.currentCameraMode,
      //   multi_view: this.deviceSetting.multi_view,
      //   is_translation_enabled: this.deviceSetting.is_translation_enabled,
      //   analytics_start_time:
      //     this.currentFormattedDate + 'T' + this.analytics_start_time,
      //   analytics_end_time:
      //     this.currentFormattedDate + 'T' + this.analytics_end_time,
      //   projector_enable: this.deviceSetting.projector_enable,
      // };

      data = Object.entries(data)
        .filter(([key, val]) => this.item[key] !== val)
        .reduce((res, el) => {
          res[el[0]] = el[1];
          return res;
        }, {});

      if (data['show_step_images'] && data['box_visualization']) {
        data['box_visualization'] = false;
      }

      if (this.isTaskChanged()) {
        data['Task'] = this.deviceDetails.Task;
        data['taskName'] = this.showTaskName(this.deviceDetails.Task);
        if (this.isTrainedTask(this.deviceDetails.Task)) {
          data['isInferenceRunning'] = false;
        }

        this.isTaskUpdating = true;
      }

      return JSON.stringify(data);
    },

    isShiftBeingManaged() {
      const { is_shift_managed } = this.item;
      if (is_shift_managed) {
        return this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
      }
      return true;
    },

    async updateDevice() {
      this.isApplyingChanges = true;
      if (!this.validateRecordInference()) {
        this.isApplyingChanges = false;
        return;
      }

      if (!this.validateRecordShift()) {
        this.isApplyingChanges = false;
        return;
      }
      const requestPayload = this.setupUpdateDeviceSetting();
      console.log('requestPayload', JSON.parse(requestPayload));
      const updatedDeviceSetting = await this.SendDeviceUpdate(requestPayload);
      updatedDeviceSetting['Task'] = updatedDeviceSetting?.Task?.id;
      const deviceInfo = updatedDeviceSetting.Device;
      delete updatedDeviceSetting.Device;
      this.$emit('updateDeviceList', {
        ...deviceInfo,
        ...updatedDeviceSetting,
        type: true,
      });
      this.isTaskUpdating = false;
      this.isApplyingChanges = false;
    },

    SendDeviceUpdate(requestPayload) {
      return new Promise(async (resolve) => {
        const [error, data] = await DeviceService.updateDeviceSettings(
          this.organization,
          this.item.id,
          requestPayload,
          false
        );
        if (error) this.renderErrorToast('Failed to update device settings');
        else
          this.toast.success(
            'We are applying your changes. This should take a minute.'
          );
        resolve(data || {});
      });
    },

    // async disableDeviceManagementBeforeUnassign() {
    //   let data = {
    //     manage: false,
    //   };
    //   const requestdata = JSON.stringify(data);
    //   return await this.SendDeviceUpdate(requestdata, false);
    // },

    openUnAssignDialog() {
      this.deviceToUnassign = this.item[this.tableRowColumns[0]];
    },

    showStream() {
      // if (this.rtcServerThreadStatus) {
      this.showStreamModal = true;
      this.streamSignal = true;
      this.$emit('isModalOpen', true);
      // }
      console.log(this.deviceSetting, this.deviceSettingUrl);
      // this.streamSignalPolling = setInterval(this.sendSignalToStream, 2000);
    },
    // handleSignalTowerModal() {
    //   this.showTowerLightModal = true;
    // },
    hidePateliteTower() {
      this.showTowerLightModal = false;
    },
    handleStreamModalClose() {
      this.streamSignal = false;
      this.$emit('closeModal', false);
      this.resetStreamPolling();
    },

    async handleSaveDeviceName() {
      this.isEditEmail = false;
      if (!this.isEditDeviceName) {
        this.isEditDeviceName = true;
        this.oldDisplayName = this.deviceDetails.display_name;
        return;
      }
      this.$emit('updateDeviceList', this.deviceDetails);
      const [error] = await DeviceService.updateDevice(
        this.item.Serial_number,
        this.deviceDetails,
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
        this.$emit('updateDeviceList', {
          ...this.deviceDetails,
          display_name: this.oldDisplayName,
        });
      }
      this.isEditDeviceName = false;
    },
    async handleSaveEmail() {
      this.isEditDeviceName = false;
      if (!this.isEditEmail) {
        this.isEditEmail = true;
        this.oldEmail = this.deviceDetails.email;
        return;
      }
      this.$emit('updateDeviceList', this.deviceDetails);
      const [error] = await DeviceService.updateDeviceSettings(
        this.organization,
        this.item.id,
        { email: this.deviceDetails.email },
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
        this.$emit('updateDeviceList', {
          ...this.deviceDetails,
          display_name: this.oldEmail,
        });
      }
      this.isEditEmail = false;
    },
    async handleChangeStarred() {
      const payload = {
        ...this.item,
        starred: !this.item.starred,
      };
      this.$emit('updateDeviceList', {
        ...payload,
      });

      const [error] = await DeviceService.updateDevice(
        this.item.Serial_number,
        payload,
        false
      );
      console.log(error);
    },

    resetPolling() {
      clearTimeout(this.polling);
      this.resetDeviceStatusPolling();
      clearInterval(this.streamSignalPolling);
    },

    resetDeviceStatusPolling() {
      clearTimeout(this.deviceStatusPolling);
    },

    resetStreamPolling() {
      clearInterval(this.streamSignalPolling);
    },

    updateDaysOfSchedule(days) {
      this.deviceDetails.day_of_week_for_recording = JSON.stringify(days);
    },
    updateTimeOfSchedule(scheduleType, start, end) {
      // if (scheduleType) this.deviceDetails.is_shift_managed = true;
      if (scheduleType === 'Recording') {
        this.deviceDetails.start_recording_time = start;
        this.deviceDetails.end_recording_time = end;
      } else if (scheduleType === 'Analytics') {
        this.deviceDetails.analytics_start_time = start;
        this.deviceDetails.analytics_end_time = end;
      } else if (scheduleType === 'Trace') {
        this.deviceDetails.trace_start_time = start;
        this.deviceDetails.trace_end_time = end;
      }
    },
    handleScheduleForSelectedType() {
      this.$refs.ScheduleTask.handleScheduleForSelectedType();
      // this.getDeviceSettings();
      // this.toggleScheduleTaskModal();
      // this.closeScheduleTaskModal();
    },
    async switchCamera(record) {
      const payload = {
        data: { switchCamera: true },
        type: 'set_flip_camera',
        message_time: new Date().toISOString(),
        platform: 'portal',
        organization: localStorage.getItem('organization'),
        device_id: record.Serial_number,
      };
      const [error] = await fcm.sendMessageToRabbitMQ(payload, false);
      if (error) throw 'Error while sending set telemetry request';
    },

    async deleteDevice() {
      try {
        this.isDeletingDevice = true;
        const serialNumber = this.item.Serial_number;
        console.log(serialNumber, this.item);

        // await this.performDeviceDeletion(serialNumber);
        const [error] = await DeviceService.deleteDevice(serialNumber, false);

        if (error)
          this.toast.error('Error while deleting Device! Please try again.');
        else {
          await this.emitDeviceDeletedEvent(serialNumber);
          this.toast.success('Device deleted successfully!');
        }
      } catch (e) {
        console.error('An error occurred:', e);
      } finally {
        this.isDeletingDevice = false;
      }
    },

    async performDeviceDeletion(serialNumber) {
      try {
        return await DeviceService.deleteDevice(serialNumber, false);
      } catch (error) {
        console.error('Error during device deletion:', error);
        return [error, null];
      }
    },

    async emitDeviceDeletedEvent(serialNumber) {
      try {
        await this.$emit('deleteDeviceFromDeviceList', serialNumber);
      } catch (error) {
        console.error('Error emitting device deletion event:', error);
      }
    },
  },
};
</script>

<style scoped>
label {
  font-size: 13px;
}

.image-icon {
  cursor: pointer;
  color: #2391ff;
}

.disabled {
  pointer-events: none;
  color: lightgray;
}

.image-icon:hover {
  color: #34495e;
  transform: scale(1.1, 1.1);
}

.display-name {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}

.custom-control-label {
  white-space: nowrap;
  font-size: 12px;
}

td {
  padding: 0.5em;
}

.download-upload-details {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center;
}

/* .stream-modal-body .ant-modal-body {
  padding: 0 12px !important;
} */
</style>
