<template>
  <div class="card w-100 p-2 h-100" style="width: 18rem">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :sm="24" :md="12" :lg="10" :xl="8">
        <a-input
          v-if="deviceList.length > 0"
          v-model:value="searchValue"
          placeholder="Search Device"
          @change="resetFilterOption"
        >
          <template #suffix>
            <search-outlined style="color: rgba(0, 0, 0, 0.45)" />
          </template>
        </a-input>
      </a-col>
    </a-row>
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div v-if="deviceList.length > 0" class="d-flex p-2">
        <h5 class="card-title mb-0 mr-auto">
          Devices: {{ getDevices.length }}
        </h5>
      </div>
      <div v-if="deviceList.length > 0" class="my-2">
        <span style="">Device Filter: &nbsp;</span>
        <a-select
          ref="select"
          v-model:value="devicefilterOption"
          style="width: 6vw"
        >
          <a-select-option value="All">All</a-select-option>
          <a-select-option value="Active">Active</a-select-option>
          <a-select-option value="Inactive">Inactive</a-select-option>
          <a-select-option value="Starred">Starred</a-select-option>
        </a-select>
      </div>
    </div>

    <div class="card-body">
      <table
        v-if="deviceList.length > 0"
        id="tableSteps"
        class="table table-borderless w-100"
        style="color: white"
      >
        <thead class="thead-dark">
          <tr>
            <th
              v-for="header in tableHeaders"
              :key="header"
              scope="col"
              style="text-align: center; background-color: black"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody id="bodyOfTable" style="margin-top: 60px">
          <tr
            v-for="(item, index) in getDevices"
            :key="item.Serial_number"
            class="border border-secondary"
          >
            <assigned-device
              :item="item"
              :index="index"
              :table-row-columns="tableRowColumns"
              :device-hover-info="deviceHoverInfo"
              :active-devices="[]"
              :all-devices="deviceList"
              :is-loading="isLoading"
              :task_list="task_list"
              :trained-tasks="trainedTasks"
              :task-id-to-task-name-map="taskIdToTaskNameMap"
              @updateDeviceList="(device) => $emit('updateDeviceList', device)"
              @deleteDeviceFromDeviceList="
                (serialNumber) =>
                  $emit('deleteDeviceFromDeviceList', serialNumber)
              "
              @isModalOpen="isModalOpen"
              @closeModal="closeModal"
            />
          </tr>
        </tbody>
      </table>
      <div v-else-if="isLoading" class="h-100 w-100 d-flex">
        <a-spin size="large" class="m-auto" />
      </div>
      <div v-else class="h-100 w-100 d-flex">
        <p class="m-auto">No Device Found</p>
      </div>
    </div>
  </div>
</template>
<script>
import AssignedDevice from './AssignedDevice.vue';
import { mapActions, mapGetters } from 'vuex';
import { SearchOutlined } from '@ant-design/icons-vue';
import DeviceConfig from 'src/config/device-config';
import { messaging, handleMessage } from 'src/utils/fcm';
import { isValidJSON } from 'src/utils/jsonHelper';
import { validRoutesName } from 'src/config/fcm-config';
import FcmMixin from 'src/mixins/FcmMixin';
import httpClient from '../../../../service/httpClient';
import DeviceService from 'src/services/device.js';
import device from 'src/services/device.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  components: { AssignedDevice, SearchOutlined },
  mixins: [FcmMixin],
  inject: ['toast'],
  props: [
    'deviceList',
    'deviceHoverInfo',
    'tableHeaders',
    'tableRowColumns',
    'department',
    'isLoading',
    'task_list',
    'trainedTasks',
    'taskIdToTaskNameMap',
  ],
  emits: ['updateDeviceList', 'deleteDeviceFromDeviceList'],
  data() {
    return {
      activeDevices: {},
      searchValue: '',
      polling: null,
      pollingTime: 1000,
      fcmMessagesDetails: {},
      devicefilterOption: 'All',
      modalOpen: false,
      previousDeviceList: {},
      activeDevicesPollingTime: 10000,
      activeDevicesPolling: null,
      macRegex: '/^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/',
    };
  },

  computed: {
    ...mapGetters(['organization', 'fcmToken']),
    getDevices() {
      const compare = (a, b) => {
        if (a.active !== b.active) {
          return a.active ? -1 : 1;
        } else if (a.serviceStatus !== b.serviceStatus) {
          return a.serviceStatus ? -1 : 1;
        } else if (a.starred !== b.starred) {
          return a.starred ? -1 : 1;
        } else {
          return 0;
        }
      };
      if (this.modalOpen) return this.previousDeviceList;

      const res = [...this.deviceList].sort(compare);
      const activeDevicesList = res.reduce((activeDeviceList, device) => {
        if (device.active) activeDeviceList.push(device);
        return activeDeviceList;
      }, []);

      const inactiveDevicesList = res.reduce((inactiveDeviceList, device) => {
        if (!device.active) inactiveDeviceList.push(device);
        return inactiveDeviceList;
      }, []);

      const starredDevicesList = res.reduce((starredDeviceList, device) => {
        if (device.starred) starredDeviceList.push(device);
        return starredDeviceList;
      }, []);

      res.map((device) => {
        if (!device.last_Active.endsWith('ago')) {
          if (device.last_Active.endsWith('UTC'))
            device.last_Active = dayjs(new Date(device.last_Active)).fromNow();
        }
      });

      if (this.devicefilterOption == 'Active') {
        return activeDevicesList;
      } else if (this.devicefilterOption == 'Inactive') {
        return inactiveDevicesList;
      } else if (this.devicefilterOption == 'Starred') {
        return starredDevicesList;
      }
      this.previousDeviceList = res;

      if (this.searchValue) this.devicefilterOption = 'All';
      if (!this.searchValue) return res;
      const result = res.reduce((acc, device) => {
        if (device.title.length > this.searchValue.length) acc.push(device);
        return acc;
      }, []);

      const inputValue = this.searchValue.toLowerCase();
      return result.filter((device) => {
        const word = device.title.toLowerCase();
        return word.includes(inputValue);
      });
    },
  },

  async created() {
    // this.polling = setInterval(this.decreaseDeviceTimeout, this.pollingTime);
    // handleMessage(messaging, this.onMessageReceived);
    this.activeDevicesPolling = setInterval(
      this.getActiveDevices,
      this.activeDevicesPollingTime
    );
    // this.polling = setInterval(this.decreaseDeviceTimeout, this.pollingTime);
  },

  beforeUnmount() {
    clearInterval(this.activeDevicesPolling);
  },
  methods: {
    ...mapActions(['setFCMToken']),
    formatDateWithCurrentTime(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        date
      );
      return formattedDate.replace(' PM', '').replace(' GMT+5', '');
    },
    isModalOpen(val) {
      this.modalOpen = true;
    },
    closeModal(val) {
      this.modalOpen = false;
    },
    async getActiveDevices() {
      if (this.isPolled) return;
      this.isPolled = true;
      const [error, response] = await DeviceService.getActiveDeviceStatus();
      const [error_service_status, response_service_status] =
        await DeviceService.getMonitoringStatus();
      if (error_service_status || error) {
        this.toast.info('Unable to receive Device status!');
      }
      let previousActiveDevices = this.activeDevices;
      this.isPolled = false;

      let serviceStatusLookup = Object.fromEntries(
        response_service_status.map((item) => [
          item.Serial_number,
          item.serviceStatus,
        ])
      );

      // Update deviceList with serviceStatus from service_status_list
      this.deviceList.forEach((device) => {
        device.serviceStatus =
          serviceStatusLookup[device.Serial_number] || false;
      });

      if (response.length < 1) {
        this.deviceList.forEach((device) => {
          device.active = false;
        });
      }

      this.activeDevices = response;
      const deviceMap = new Map();

      this.activeDevices.forEach((device) => {
        if (device.Task && typeof device.Task === 'object')
          device.Task = device.Task.id;
      });
      // Update the map with devices from activeDevices list
      this.activeDevices.forEach((device) =>
        deviceMap.set(device.Serial_number, { ...device, active: true })
      );

      // Add devices from previousActiveDevices list that are not in activeDevices
      if (Array.isArray(previousActiveDevices)) {
        previousActiveDevices.forEach((device) => {
          if (!deviceMap.has(device.Serial_number)) {
            deviceMap.set(device.Serial_number, { ...device, active: false });
          }
        });
      }

      // Convert the Map values back to an array
      const mergedList = Array.from(deviceMap.values());
      // console.log('mergedList', mergedList);
      mergedList.forEach((device) => {
        this.handleDeviceStatus(device);
      });
    },
    decreaseDeviceTimeout() {
      const temp = {};
      this.getActiveDevices();
      // Object.entries(this.activeDevices).forEach(([Serial_number, timeout]) => {
      //   // this.$emit('updateDeviceList', {
      //   //   Serial_number,
      //   //   active: false,
      //   //   rtc_thread_status: false
      //   // });
      //   if (timeout === 1) {
      //     this.$emit('updateDeviceList', {
      //       Serial_number,
      //       active: false,
      //       serviceStatus: false,
      //       rtc_thread_status: false
      //     });
      //   } else temp[Serial_number] = timeout - 1;
      // });
    },

    // handleDeviceStatus(data) {
    //   const deviceStatus = data;
    //   // console.log('deviceStatus', deviceStatus);
    //   const serialNumber = deviceStatus.Serial_number;
    //   const { uploading, downloading, active } = deviceStatus;
    //   if (active) {
    //     this.activeDevices = {
    //       ...this.activeDevices,
    //       [serialNumber]: 20
    //     };
    //     this.$emit('updateDeviceList', {
    //       ...deviceStatus,
    //       uploading,
    //       downloading
    //     });
    //   } else {
    //     this.activeDevices = {
    //       ...this.activeDevices
    //     };
    //     this.$emit('updateDeviceList', {
    //       ...deviceStatus,
    //       uploading,
    //       downloading
    //     });
    //   }
    // },

    async handleDeviceStatus(device) {
      // // if (typeof device !== 'object' && device.length > 1) {
      // const [error, data] = await DeviceService.getDeviceSettingOfActiveDevice(
      //   device.Serial_number,
      //   this.organization
      // );
      // if (data) {
      // const deviceStatus = { ...data[0].Device, ...data[0] };
      const deviceStatus = device;

      const serialNumber = deviceStatus.Serial_number;
      const { active } = device;
      const { uploading, downloading } = device;
      if (active) {
        this.activeDevices = [...this.activeDevices];

        this.$emit('updateDeviceList', {
          ...deviceStatus,
          uploading,
          active: true,
          downloading,
        });
      } else {
        this.activeDevices = {
          ...this.activeDevices,
        };
        this.$emit('updateDeviceList', {
          ...deviceStatus,
          uploading,
          downloading,
          active: false,
        });
      }
      // }

      // }
    },
    handleDeviceSetting(data) {
      // console.log('deviceSettings', data);
      const deviceSettings = data;

      if (typeof deviceSettings !== 'object') return;

      const device = deviceSettings?.Device;
      const task = deviceSettings?.Task;

      this.activeDevices = {
        ...this.activeDevices,
        [device.Serial_number]: 20,
      };

      if (task && typeof task === 'object') deviceSettings['Task'] = task.id;

      delete deviceSettings['Device'];
      // console.log({ device });
      this.$emit('updateDeviceList', { ...device, ...deviceSettings });
    },

    handleLiveStream(data) {
      const deviceData = data;
      const serialNumber = deviceData.device;
      this.emitter.emit(serialNumber, deviceData);
    },

    handleDiscoverDevicesOpcua(data) {
      console.log('OPCUA Devices: ', data);
    },
    onMessageReceived(payload) {
      if (!validRoutesName.includes(this.$route.name)) return;
      const _data = this.getMessageData(payload);
      if (!_data) return;

      let types = [
        DeviceConfig.device_status,
        DeviceConfig.device_setting,
        DeviceConfig.web_rtc_stream_offer,
      ];

      const handlers = {
        [DeviceConfig.device_status]: this.handleDeviceStatus,
        [DeviceConfig.device_setting]: this.handleDeviceSetting,
        [DeviceConfig.web_rtc_stream_offer]: this.handleLiveStream,
        // [DeviceConfig.discover_devices_opcua]: this.handleDiscoverDevicesOpcua,
      };

      const { type } = payload?.data;
      // console.log(type in types, type, types);
      if (types.includes(type)) handlers[type](_data);
    },
    resetFilterOption() {
      this.devicefilterOption = 'All';
    },
  },
};
</script>

<style scoped>
.card-body {
  overflow: auto;
  height: inherit;
  padding: 0 0.5em;
}
</style>
