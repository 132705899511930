<template>
  <main-page></main-page>
</template>

<script>
import MainPage from './MainPage.vue';

export default {
  components: {
    MainPage,
  },
};
</script>
