<template>
  <a-row class="p-4 h-100">
    <a-col span="24" class="h-100">
      <device-list
        :device-list="deviceList"
        :device-hover-info="deviceHoverInfo"
        :table-headers="tableHeaders"
        :table-row-columns="tableRowColumns"
        :is-loading="isLoading"
        :task_list="task_list"
        :trained-tasks="trainedTasks"
        :task-id-to-task-name-map="taskIdToTaskNameMap"
        @updateDeviceList="updateDeviceList"
        @deleteDeviceFromDeviceList="deleteDeviceFromDeviceList"
      />
    </a-col>
  </a-row>
</template>
<script>
import DeviceList from './DeviceList.vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { showNotification } from '../../../shared/Helpers/showNotification';
import { notification } from 'ant-design-vue';
import DeviceService from 'src/services/device';
import TaskService from 'src/services/tasks';
import trainingStatuses from '@/config/training-status-config.js';

export default {
  components: { DeviceList },
  inject: ['toast'],
  data() {
    return {
      tableHeaders: [
        '',
        'DEVICE DETAILS',
        'MANAGE ONLINE',
        'RECORD CONTROLS',
        'DEVICE CONTROLS',
        'CAMERA CONTROLS',
        'CURRENT OPERATION',
        // 'SCHEDULE TASK',
        'GUIDANCE CONTROLS',
        ''
      ],
      deviceHoverInfo: [
        { key: 'Serial_number', title: 'Serial Number' },
        { key: 'last_Active', title: 'Last Active' },
        { key: 'version_no', title: 'Version' },
        { key: 'os_info', title: 'OS Info' },
        { key: 'gpu', title: 'GPU' },
        { key: 'ram', title: 'RAM' },
        { key: 'Organization', title: 'Organization' },
        { key: 'email', title: 'Email Address' }
      ],
      tableRowColumns: [
        'Serial_number',
        'device_active_status',
        'Current_task_running',
        'Manage',
        [
          'Blur Face',
          'Record Trace',
          'Use Start/End Time',
          'Record Training Set',
          'Multiple Cameras',
          'Remote Cameras',
          'Enable Translation',
          'Depth Camera',
          'Projector',
          'Box Visualization',
          'Previous Cycle Error',
          'Show/Hide Cameras',
          'Enable Auto Updates',
          'Show/Hide Step Media',
          'Show/Hide Task Steps',
          'Cap FPS'
        ],
        'inferenceControls',
        'updateDevice',
        'display_name'
      ],
      deviceList: [],
      organization: localStorage.getItem('organization'),
      isLoading: true,
      task_list: [],
      taskIdToTaskNameMap: {},
      trainedTasks: []
    };
  },

  created() {
    this.getOrgDevices();
    this.showNotification();
  },
  beforeUnmount() {
    notification.destroy();
  },

  methods: {
    async getOrgDevices() {
      const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
        this.organization,
        false
      );
      if (error) {
        console.log(error);
        return;
      }
      await this.getTaskList();
      await this.getTrainedTaskList();

      this.deviceList = data
        .filter(d => d.Type !== 'departmental device')
        .map(d => ({
          ...d,
          active: false,
          serviceStatus: false,
          title: d.display_name || d.Serial_number,
          task: d.Task && this.taskIdToTaskNameMap[d.Task]
        }));
      this.isLoading = false;
    },

    showNotification() {
      setTimeout(
        showNotification,
        2000,
        'Looking for Device(s)',
        'We are looking for active devices in your organization. This will take a few seconds.',
        InfoCircleOutlined,
        '#108ee9'
      );
    },

    updateDeviceList(deviceDetails) {
      const { Serial_number } = deviceDetails;
      const temp = [...this.deviceList];
      const index = temp.findIndex(v => v.Serial_number === Serial_number);
      if (!temp[index]) return;
      let title = '';
      if ('display_name' in deviceDetails) {
        const { display_name, Serial_number } = deviceDetails;
        title = display_name || Serial_number;
      } else {
        const { display_name, Serial_number } = temp[index];
        title = display_name || Serial_number;
      }
      temp[index] = {
        ...temp[index],
        ...deviceDetails,
        title
      };

      this.deviceList = temp;
    },

    deleteDeviceFromDeviceList(serialNumber) {
      console.log(serialNumber);
      const filteredDevices = this.deviceList.filter(
        device => device.Serial_number !== serialNumber
      );
      console.log(filteredDevices, filteredDevices.length);

      if (filteredDevices.length < this.deviceList.length) {
        this.deviceList = filteredDevices;
        console.log(filteredDevices, filteredDevices.length);
        console.log(
          `Device with serial number ${serialNumber} deleted successfully.`
        );
      } else {
        console.log(`Device with serial number ${serialNumber} not found.`);
      }
    },

    getTaskList() {
      return new Promise(async (resolve, _) => {
        const [error, data] = await TaskService.fetchTasks(false);
        if (error) {
          console.log(error);
          return resolve();
        }
        this.task_list = data;
        this.taskIdToTaskNameMap = data.reduce((res, task) => {
          res[task.id] = task.taskName;
          return res;
        }, {});
        resolve();
      });
    },

    getTrainedTaskList() {
      return new Promise(async (resolve, _) => {
        const [error, data] = await TaskService.fetchTrainedTasksByStatus(
          trainingStatuses.trained,
          false
        );
        if (error) {
          console.log(error);
          return resolve();
        }
        this.trainedTasks = data;
        resolve();
      });
    }
  }
};
</script>
